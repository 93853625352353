import { CloseCircleOutlined, EditFilled } from '@ant-design/icons';
import { useEmbeddings } from '@client/EmbeddingsClient';
import { IEmbedding } from '@shared/embeddings';
import { PageContent } from '@web/app/Page';
import { del } from '@web/common/api';
import { formatDate } from '@web/common/formatDate';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header1, Small, Text } from '@web/components/typography';
import { Button, Skeleton, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import * as React from 'react';

import { EditEmbeddingModal } from './EditEmbeddingModal';

export const AdminEmbeddingsPage: React.FC = () => {
  const { confirm, contextHolder } = useModalConfirm();
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState<IEmbedding>(null);
  const [page, setPage] = React.useState(1);
  const { data: embeddings, mutate: reloadEmbeddings } = useEmbeddings();

  const handleRemoveFile = async (embedding: IEmbedding) => {
    const confirmed = await confirm(
      'Do you wish to permanently remove this embedding?',
      { title: 'Delete File' },
    );
    if (!confirmed) {
      return;
    }

    try {
      await del(`/embeddings/${embedding.token}`);
      void message.success('Success');
      void reloadEmbeddings();
    } catch (error) {
      void message.error('Error');
    }
  };

  const columns: ColumnsType<IEmbedding> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, embedding) => {
        return (
          <Row gap={12}>
            <Column>
              <Text>{embedding.name}</Text>
              <Small
                style={{
                  color: '#888',
                  fontStyle: embedding.description ? undefined : 'italic',
                }}
              >
                {embedding.description ?? 'No description'}
              </Small>
            </Column>
          </Row>
        );
      },
    },
    {
      title: 'Created',
      key: 'createdDate',
      dataIndex: 'createdDate',
      render: (createdDate) =>
        createdDate ? <Text>{formatDate(createdDate)}</Text> : '',
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      render: (_, file) => {
        return (
          <Row gap={8} style={{ justifyContent: 'flex-end' }}>
            <Tooltip title="Edit embedding">
              <EditFilled
                style={{ fontSize: 16, color: '#666' }}
                onClick={() => {
                  setEditing(file);
                }}
              />
            </Tooltip>
            <Tooltip title="Remove embedding">
              <CloseCircleOutlined
                style={{ fontSize: 16, color: '#666' }}
                onClick={() => {
                  void handleRemoveFile(file);
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <PageContent>
      <Row>
        <Header1>Embeddings</Header1>
        <GrowingSpacer />
        <Button
          onClick={() => {
            setCreating(true);
          }}
        >
          Add new
        </Button>
      </Row>
      <Spacer />
      {embeddings ? (
        <Table
          rowKey="token"
          pagination={{
            total: embeddings.length,
            onChange: setPage,
            current: page,
            pageSize: 50,
            showSizeChanger: false,
            showTotal: (total) => `${total} files`,
          }}
          columns={columns}
          dataSource={embeddings}
        />
      ) : (
        <Skeleton />
      )}
      {contextHolder}
      {editing && (
        <EditEmbeddingModal
          embedding={editing}
          onClose={() => {
            setEditing(null);
            setCreating(false);
          }}
          onSave={() => {
            setEditing(null);
            setCreating(false);
            void reloadEmbeddings();
          }}
        />
      )}
      {creating && (
        <EditEmbeddingModal
          onClose={() => {
            setEditing(null);
            setCreating(false);
          }}
          onSave={() => {
            setEditing(null);
            void reloadEmbeddings();
            setCreating(false);
          }}
        />
      )}
    </PageContent>
  );
};
