import { ArrowLeftOutlined } from '@ant-design/icons';
import { questionPrompt } from '@client/ChatClient';
import { IChatCompletion } from '@shared/completions';
import { PageContent } from '@web/app/Page';
import { Markdown } from '@web/components/Markdown';
import { Column, Spacer } from '@web/components/layout';
import { Header3 } from '@web/components/typography';
import { Button, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';

export const ChatPage: React.FC = () => {
  const [prompt, setPrompt] = React.useState('');
  const [promptCompletion, setPromptCompletion] =
    React.useState<IChatCompletion>(null);

  const handlePromptSubmit = async (prompt: string) => {
    if (prompt.length === 0) {
      setPromptCompletion(null);
      return;
    }

    void message.loading('🤖 Thinking... beep bop beep...');

    try {
      const completion = await questionPrompt(prompt);
      setPromptCompletion(completion);
      message.destroy();
    } catch (error) {
      await message.destroy();
      void message.error('Error');
    }
  };

  return (
    <PageContent>
      <Column
        style={{
          maxWidth: 500,
          width: '100%',
          alignSelf: 'center',
          padding: 24,
        }}
      >
        {' '}
        {promptCompletion ? (
          <>
            <Header3 style={{ fontWeight: 500 }}>{prompt}</Header3>
            <Spacer />
            <Markdown value={promptCompletion.response} />
            <Spacer />
            <Button
              onClick={() => {
                setPrompt('');
                setPromptCompletion(null);
              }}
              style={{
                width: '200px',
              }}
            >
              <ArrowLeftOutlined /> Ask another question
            </Button>
          </>
        ) : (
          <>
            <Header3>What do you need help with?</Header3>
            <Spacer />
            <TextArea
              allowClear
              value={prompt}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  void handlePromptSubmit(prompt);
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onChange={(e) => {
                setPrompt(e.currentTarget.value);
              }}
              autoSize
            />
          </>
        )}
      </Column>
    </PageContent>
  );
};
