/* eslint-disable @typescript-eslint/promise-function-async */
import { LOGIN_REDIRECT_PATH_KEY } from '@shared/constants';
import { CUSTOMER_ADMIN_ROLES, CUSTOMER_DIRECTOR_ROLES } from '@shared/types';
import { AdminAgendaItemsPage } from '@web/admin/AdminAgendaItemsPage';
import { AdminEmbeddingsPage } from '@web/admin/AdminEmbeddingsPage';
import { AdminFilesPage } from '@web/admin/AdminFilesPage';
import { AdminLoginPage } from '@web/auth/AdminLoginPage';
import { LoginPage } from '@web/auth/LoginPage';
import { LoginRedirect } from '@web/auth/LoginRedirect';
import { RolesRouteGuard } from '@web/auth/RolesRouteGuard';
import { useAuth } from '@web/auth/useAuth';
import { ChatPage } from '@web/home/ChatPage';
import { HomePage } from '@web/home/HomePage';
import { ActionItemsPage } from '@web/meetings/ActionItemsPage';
import { EditMeetingPage } from '@web/meetings/EditMeetingPage';
import { MeetingPlanningPage } from '@web/meetings/MeetingPlanningPage';
import { MeetingsPage } from '@web/meetings/MeetingsPage';
import { NewMeetingPage } from '@web/meetings/NewMeetingPage';
import { ViewAgendaPage } from '@web/meetings/ViewAgendaPage';
import { EditTopicPage } from '@web/topics/EditTopicPage';
import { NewTopicPage } from '@web/topics/NewTopicPage';
import { TopicPage } from '@web/topics/TopicPage';
import { TopicsPage } from '@web/topics/TopicsPage';
import UserSettingsPage from '@web/users/UserSettingsPage/UserSettingsPage';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { NotFoundPage } from './NotFoundPage';
import { internalRoutes } from './routing/internalRoutes';

export const AppRoutes: React.FC = () => {
  const { isLoading, isLoggedIn, isSuper } = useAuth();

  if (isLoading) {
    return null;
  }

  if (isSuper) {
    return (
      <React.Suspense>
        <Routes>
          <Route index element={<LoginRedirect />} />
          <Route path="redirect" element={<LoginRedirect />} />
          {internalRoutes()}
          <Route path="user/settings" element={<UserSettingsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </React.Suspense>
    );
  }

  return (
    <React.Suspense>
      <Routes>
        <Route index element={isLoggedIn ? <LoginRedirect /> : <LoginPage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="chat" element={<ChatPage />} />
        <Route path="redirect" element={<LoginRedirect />} />
        <Route
          path="action-items"
          element={<RolesRouteGuard roles={CUSTOMER_DIRECTOR_ROLES} />}
        >
          <Route index element={<ActionItemsPage />} />
          <Route path=":actionItemToken" element={<ActionItemsPage />} />
        </Route>
        <Route
          path="admin"
          element={<RolesRouteGuard roles={CUSTOMER_ADMIN_ROLES} />}
        >
          <Route path="files">
            <Route index element={<AdminFilesPage />} />
          </Route>
          <Route path="embeddings">
            <Route index element={<AdminEmbeddingsPage />} />
          </Route>
          <Route path="agenda-items">
            <Route index element={<AdminAgendaItemsPage />} />
          </Route>
        </Route>
        <Route
          path="topics"
          element={<RolesRouteGuard roles={CUSTOMER_DIRECTOR_ROLES} />}
        >
          <Route index element={<TopicsPage />} />
          <Route path="new" element={<NewTopicPage />} />
          <Route path=":topicToken" element={<TopicPage />} />
          <Route path=":topicToken/edit" element={<EditTopicPage />} />
        </Route>
        <Route
          path="meetings"
          element={<RolesRouteGuard roles={CUSTOMER_DIRECTOR_ROLES} />}
        >
          <Route index element={<MeetingsPage />} />
          <Route path="new" element={<NewMeetingPage />} />
          <Route path=":meetingToken" element={<MeetingPlanningPage />} />
          <Route
            path=":meetingToken/agenda-items/:agendaItemToken"
            element={<MeetingPlanningPage />}
          />
          <Route path=":meetingToken/agenda" element={<ViewAgendaPage />} />
          <Route path=":meetingToken/edit" element={<EditMeetingPage />} />
        </Route>
        <Route
          path="user"
          element={<RolesRouteGuard roles={CUSTOMER_DIRECTOR_ROLES} />}
        >
          <Route
            path="profile"
            element={<Navigate to="/user/settings" replace />}
          />
          <Route path="settings" element={<UserSettingsPage />} />
        </Route>
        <Route
          path="/auth/admin/login"
          element={!isLoggedIn ? <AdminLoginPage /> : <LoginRedirect />}
        />
        <Route
          path="/auth/login"
          element={!isLoggedIn ? <LoginPage /> : <LoginRedirect />}
        />
        {!isLoggedIn ? (
          <Route path="*" element={<LoggedOutRedirect />} />
        ) : (
          <Route path="*" element={<NotFoundPage />} />
        )}
      </Routes>
    </React.Suspense>
  );
};

const LoggedOutRedirect: React.FC = () => {
  window.localStorage[LOGIN_REDIRECT_PATH_KEY] =
    window.location.pathname + window.location.search;
  return <Navigate to="/auth/login" replace />;
};
