import { OrganizationToken } from '@shared/organizations';
import { IUser, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { post } from '@web/common/api';
import { IS_PRODUCTION } from '@web/common/const';
import { formatLongDate } from '@web/common/formatDate';
import { useApi } from '@web/common/useApi';
import { ConfirmButton } from '@web/components/ConfirmButton';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column, Grid } from '@web/components/layout';
import { ButtonProps, message } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router';

import { DateField } from './DateField';

const UserActivityPage: React.FC = () => {
  const { impersonate } = useAuth();
  const { userToken, organizationToken } = useParams<{
    userToken: UserToken;
    organizationToken: OrganizationToken;
  }>();
  const { data: viewingUser, mutate: refreshViewingUser } = useApi<IUser>(
    `/users/${userToken}`,
  );

  if (!viewingUser) {
    return null;
  }

  const handleSendAdminInvite = async () => {
    try {
      await post(`/notifications/admin/${userToken}/invite`);
      void message.success('Sent admin invite');
    } catch (error) {
      void message.error('Failed to send admin invite');
    }
  };

  return (
    <PageContent>
      <PageHeader
        title={`${viewingUser.name}'s Activity`}
        description={`User created on ${formatLongDate(
          viewingUser.createdDate,
          true,
        )}`}
        navigateBack
        defaultNavigateBackTo={`/organizations/${organizationToken}/users`}
        secondaryActions={[
          {
            label: 'Impersonate',
            onClick: () => {
              void impersonate(viewingUser.token);
            },
            confirm: {
              title: `Are you sure?`,
              description:
                'Everything done while impersonating this user will effect their account!',
              okText: 'Impersonate',
            },
          },
          {
            label: 'Send Admin Invite',
            onClick: handleSendAdminInvite,
            confirm: {
              title: 'Send Admin Invite',
              description:
                'Are you sure you wish to send an admin invitation to this user?',
            },
          },
        ].filter((action) => !!action)}
      />
      <Column gap={24}>
        <Pane>
          <Grid gap={24} columns="2fr 1fr">
            <Column>
              <DateField
                label="Invited Date"
                user={viewingUser}
                property="invitedDate"
                onChange={refreshViewingUser}
                extra={
                  <UserActionButton
                    label="Send invite"
                    postUrl={`/internal/users/${userToken}/send/invite`}
                    userToken={userToken}
                    type="text"
                    onSuccess={refreshViewingUser}
                  />
                }
              />
              <DateField
                label="First Login Date"
                user={viewingUser}
                property="firstLoginDate"
                onChange={refreshViewingUser}
              />
            </Column>
            <Column gap={12}>
              <UserActionButton
                label="Send a creation reminder"
                postUrl={`/internal/notifications/users/${userToken}/sendCreateReminder`}
                userToken={userToken}
              />
              <UserActionButton
                label="Send a suggest entry notification"
                postUrl={`/internal/notifications/users/${userToken}/sendSuggestEntry`}
                userToken={userToken}
              />
              {!IS_PRODUCTION && (
                <UserActionButton
                  label="Send manager inactivity notification"
                  postUrl={`/impacts/notifications/${userToken}/sendInactivityToManager`}
                  userToken={userToken}
                />
              )}
              <UserActionButton
                label="Start peer review cycle"
                postUrl={`/peer-reviews/${userToken}/new`}
                userToken={userToken}
              />
              <UserActionButton
                label="Send dimension feedback request notification"
                postUrl={`/feedback_requests/dimension-feedback-request/${organizationToken}/${userToken}`}
                userToken={userToken}
              />
            </Column>
          </Grid>
        </Pane>
      </Column>
    </PageContent>
  );
};

export default UserActivityPage;

const UserActionButton: React.FC<{
  userToken: UserToken;
  label: string;
  postUrl: string;
  disabled?: boolean;
  type?: ButtonProps['type'];
  onSuccess?: () => void;
}> = ({ label, postUrl, userToken, disabled, type, onSuccess }) => {
  const handleSendCreationReminder = async () => {
    try {
      await post(postUrl);
      void message.success('Sent');
      onSuccess?.();
    } catch (error) {
      void message.error(error.message ?? 'Error');
    }
  };

  return (
    <ConfirmButton
      onConfirm={handleSendCreationReminder}
      title="Confirm"
      description={`Please confirm you wish to "${label.toLowerCase()}" for user ${userToken}.`}
      okText="Continue"
      disabled={disabled}
      type={type}
    >
      {label}
    </ConfirmButton>
  );
};
