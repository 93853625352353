import { createEmbedding, updateEmbedding } from '@client/EmbeddingsClient';
import { IEmbedding } from '@shared/embeddings';
import { Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Input, Modal, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';

export const EditEmbeddingModal: React.FC<{
  embedding?: IEmbedding;
  onSave: (embedding: IEmbedding) => void;
  onClose: () => void;
}> = ({ embedding, onClose, onSave }) => {
  const [name, setName] = React.useState(embedding?.name);
  const [description, setDescription] = React.useState(embedding?.description);
  const [text, setText] = React.useState(embedding?.text);

  const handleOkClicked = async () => {
    if (!name || !description || !text) {
      return;
    }

    try {
      let saved: IEmbedding;

      if (embedding) {
        void message.loading('Updating...');
        saved = await updateEmbedding(embedding.token, {
          text,
          name,
          description,
        });
      } else {
        void message.loading('Creating...');
        saved = await createEmbedding({
          entityToken: 'f_fake',
          text,
          name,
          description,
        });
      }
      onSave(saved);
      await message.destroy();
    } catch (error) {
      await message.destroy();
      void message.error('Error');
    }
  };

  return (
    <Modal
      title={embedding ? 'Edit Embedding' : 'Create Embedding'}
      open={true}
      onOk={handleOkClicked}
      onCancel={onClose}
      okButtonProps={{ disabled: !text || !name || !description }}
      width={800}
      okText="Save"
    >
      <Spacer size={12} />
      <Text>Name</Text>
      <Input
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <Spacer size={12} />
      <Text>Prompt Description</Text>
      <Input
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <Spacer size={12} />
      <Text>Text</Text>
      <TextArea
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        autoSize
        style={{
          maxHeight: `calc(100vh - 450px)`,
        }}
      />
      <Spacer />
    </Modal>
  );
};
