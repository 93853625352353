import {
  ApartmentOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  SlackOutlined,
} from '@ant-design/icons';
import { useAuth } from '@web/auth/useAuth';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header3 } from '@web/components/typography';
import OrganizationsModal from '@web/organizations/OrganizationsModal';
import { Typography } from 'antd';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { OrganizationPicker } from './OrganizationPicker';
import { useOrganizationFilter } from './organizationFilter';

export const Sidebar: React.FC<{ width: number }> = ({ width }) => {
  const { isSuper } = useAuth();
  const isInternal = isSuper;

  return (
    <Container>
      {isInternal ? <InternalSidebar /> : <CustomerSidebar />}
    </Container>
  );
};

const InternalSidebar: React.FC = () => {
  const { organizationTokenFilter } = useOrganizationFilter();
  const [showOrganizationsModal, setShowOrganizationsModal] =
    React.useState(false);
  const organizationPath = `/organizations/${organizationTokenFilter}`;

  return (
    <Column gap={18} style={{ height: '100%' }}>
      <Row gap={6}>
        <HomeOutlined style={{ fontSize: 16 }} />
        <Header3>Condoo</Header3>
      </Row>
      <SidebarLinks>
        {organizationTokenFilter && (
          <>
            <SidebarLink to={`${organizationPath}/details`}>
              <ShopOutlined /> Organization
            </SidebarLink>
            <SidebarLink to={`${organizationPath}/users`}>
              <ApartmentOutlined /> Users
            </SidebarLink>
            <SidebarLink to={`${organizationPath}/notifications`}>
              <SlackOutlined /> Notifications
            </SidebarLink>
          </>
        )}
        <SidebarLink to="/user/settings">
          <SettingOutlined /> Settings
        </SidebarLink>
      </SidebarLinks>
      <GrowingSpacer />
      <OrganizationPicker
        onClick={() => {
          setShowOrganizationsModal(true);
        }}
      />
      <OrganizationsModal
        onClose={() => {
          setShowOrganizationsModal(false);
        }}
        open={showOrganizationsModal}
      />
    </Column>
  );
};

const CustomerSidebar: React.FC<{}> = () => {
  return (
    <>
      <Spacer size={18} />
      <SidebarLinks>
        <SidebarLink to="/user/settings">
          <SettingOutlined /> Settings
        </SidebarLink>
        <SidebarLink url="https://condoo.cc/help-center">
          <QuestionCircleOutlined /> Help Center
        </SidebarLink>
      </SidebarLinks>
    </>
  );
};

const SidebarLink: React.FC<{
  to?: string;
  url?: string;
  children: React.ReactNode;
}> = ({ to, url, children }) =>
  url ? (
    <a href={url} target="_blank" rel="noreferrer" className="sidebar-link">
      <Typography.Text>{children}</Typography.Text>
    </a>
  ) : (
    <NavLink to={to} className="sidebar-link">
      <Typography.Text>{children}</Typography.Text>
    </NavLink>
  );

export const PoweredByCondoo: React.FC = () => {
  return (
    <Row style={{ justifyContent: 'center' }}>
      <Typography.Text
        style={{ fontSize: 12, color: '#888', lineHeight: '18px' }}
      >
        Powered by condoz.cc
      </Typography.Text>
    </Row>
  );
};

const Container = styled.section`
  top: 0;
  height: 100%;
  position: fixed;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-right: 1px solid #eee;
`;

const SidebarLinks = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;

  svg {
    margin-right: 5px;
  }

  .sidebar-link {
    cursor: pointer;
    border-radius: var(--default-border-radius);
    transition: all 0.2s linear;
    padding: 0 18px;
    white-space: nowrap;

    > span {
      margin: 0;
      display: flex;
      gap: 12px;
      align-items: center;
      line-height: 40px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.1px;
      color: #666;

      svg {
        font-size: 18px;
        padding: 2px;
        margin: 0;
      }
    }
  }

  .sidebar-link:hover {
    background-color: var(--hover-background);
  }

  .sidebar-link.active {
    background-color: var(--ant-primary-color);

    > span {
      color: white;
    }
  }
`;
